<template>
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi b-icon">
        <path d="M6 10 H14 M10 6 V14" stroke="white" stroke-width="2" stroke-linecap="round" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconPlus',
})
</script>
