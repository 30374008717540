<template>
    <svg width="42" height="17" viewBox="0 0 42 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.25833 0H1.86667C0.835735 0 0 1.17095 0 2.61538V5.55769H0.75V2.61538C0.75 2.03133 0.920105 1.53392 1.1572 1.20173C1.39534 0.868069 1.65654 0.75 1.86667 0.75H4.25833V0Z"
            fill="currentColor"
        />
        <path
            d="M0 11.4423V14.3846C0 15.8291 0.835735 17 1.86667 17H4.25833V16.25H1.86667C1.65654 16.25 1.39534 16.1319 1.1572 15.7983C0.920105 15.4661 0.75 14.9687 0.75 14.3846V11.4423H0Z"
            fill="currentColor"
        />
        <path d="M9.04167 0V0.75H13.825V0H9.04167Z" fill="currentColor" />
        <path d="M18.6083 0V0.75H23.3917V0H18.6083Z" fill="currentColor" />
        <path d="M28.175 0V0.75H32.9583V0H28.175Z" fill="currentColor" />
        <path
            d="M37.7417 0V0.75H40.1333C40.3435 0.75 40.6047 0.868069 40.8428 1.20173C41.0799 1.53392 41.25 2.03133 41.25 2.61538V5.55769H42V2.61538C42 1.17095 41.1643 0 40.1333 0H37.7417Z"
            fill="currentColor"
        />
        <path
            d="M42 11.4423H41.25V14.3846C41.25 14.9687 41.0799 15.4661 40.8428 15.7983C40.6047 16.1319 40.3435 16.25 40.1333 16.25H37.7417V17H40.1333C41.1643 17 42 15.8291 42 14.3846V11.4423Z"
            fill="currentColor"
        />
        <path d="M32.9583 17V16.25H28.175V17H32.9583Z" fill="currentColor" />
        <path d="M23.3917 17V16.25H18.6083V17H23.3917Z" fill="currentColor" />
        <path d="M13.825 17V16.25H9.04167V17H13.825Z" fill="currentColor" />
        <path d="M21 3L24 6L23.2929 6.70711L21 4.41421L18.7071 6.70711L18 6L21 3Z" fill="currentColor" />
        <path d="M21 13L18 10L18.7071 9.29289L21 11.5858L23.2929 9.29289L24 10L21 13Z" fill="currentColor" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconBlankSpace',
})
</script>
