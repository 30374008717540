<template>
    <svg width="42" height="15" viewBox="0 0 42 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.03342 8.99556L8.90859 11.7537H11.6662L7.38942 0.118884H4.26027L0 11.7537H2.75762L3.63279 8.99556H8.03342ZM6.0519 2.69471C6.15648 3.0223 6.61333 4.4357 7.42245 6.9349H4.27678C5.13544 4.26662 5.65559 2.59431 5.83723 1.918C5.88127 2.10821 5.95282 2.36711 6.0519 2.69471Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.3426 0.166438H17.586V11.7537H22.1022C23.4673 11.7537 24.5461 11.4578 25.3387 10.866C26.1368 10.2742 26.5359 9.44996 26.5359 8.39322C26.5359 7.64821 26.3652 7.05115 26.024 6.60203C25.6882 6.15291 25.1378 5.84645 24.3727 5.68266V5.6034C24.9341 5.51358 25.3827 5.24675 25.7185 4.80292C26.0598 4.35908 26.2304 3.79108 26.2304 3.09891C26.2304 2.07915 25.8423 1.33679 25.0662 0.871817C24.2957 0.401564 23.0545 0.166438 21.3426 0.166438ZM21.6316 4.75536H20.1455V2.17954H21.4913C22.2233 2.17954 22.7572 2.27729 23.093 2.47279C23.4342 2.663 23.6049 2.98003 23.6049 3.42386C23.6049 3.8994 23.448 4.2402 23.1343 4.44626C22.826 4.65233 22.3251 4.75536 21.6316 4.75536ZM20.1455 9.72472V6.70506H21.7307C23.1618 6.70506 23.8773 7.18588 23.8773 8.14752C23.8773 8.68118 23.7095 9.07746 23.3737 9.33636C23.0379 9.59527 22.5178 9.72472 21.8132 9.72472H20.1455Z"
            fill="currentColor"
        />
        <path
            d="M36.2288 3.09099C36.7572 2.39353 37.503 2.04481 38.4663 2.04481C38.9066 2.04481 39.3552 2.12142 39.8121 2.27465C40.2689 2.42788 40.723 2.60752 41.1744 2.81359L42 0.816337C40.8166 0.272112 39.6387 0 38.4663 0C37.3159 0 36.3114 0.243052 35.4527 0.729156C34.5996 1.20998 33.9446 1.90479 33.4877 2.81359C33.0309 3.71711 32.8024 4.77121 32.8024 5.97591C32.8024 7.88862 33.2675 9.3575 34.1978 10.3825C35.128 11.4023 36.471 11.9122 38.2269 11.9122C39.4488 11.9122 40.5551 11.714 41.5459 11.3178V9.25711C40.3405 9.66395 39.3139 9.86738 38.4663 9.86738C36.4462 9.86738 35.4362 8.5755 35.4362 5.99176C35.4362 4.75008 35.7004 3.78316 36.2288 3.09099Z"
            fill="currentColor"
        />
        <path d="M13.5272 15H32.1271V13.3768H13.5272V15Z" fill="currentColor" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconFillInTheBlank',
})
</script>
