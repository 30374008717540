<template>
    <svg width="23" height="10" viewBox="0 0 23 10" fill="white" xmlns="http://www.w3.org/2000/svg" class="bi b-icon">
        <ellipse cx="14.6364" cy="2.14285" rx="2.14285" ry="2.09091" transform="rotate(90 14.6364 2.14285)" />
        <ellipse cx="8.36368" cy="2.14285" rx="2.14285" ry="2.09091" transform="rotate(90 8.36368 2.14285)" />
        <ellipse cx="2.09085" cy="2.14285" rx="2.14285" ry="2.09091" transform="rotate(90 2.09085 2.14285)" />
        <ellipse cx="14.6364" cy="7.85709" rx="2.14285" ry="2.09091" transform="rotate(90 14.6364 7.85709)" />
        <ellipse cx="20.9091" cy="2.14285" rx="2.14285" ry="2.09091" transform="rotate(90 20.9091 2.14285)" />
        <ellipse cx="20.9091" cy="7.85709" rx="2.14285" ry="2.09091" transform="rotate(90 20.9091 7.85709)" />
        <ellipse cx="8.36368" cy="7.85709" rx="2.14285" ry="2.09091" transform="rotate(90 8.36368 7.85709)" />
        <ellipse cx="2.09097" cy="7.85709" rx="2.14285" ry="2.09091" transform="rotate(90 2.09097 7.85709)" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconEightDots',
})
</script>
