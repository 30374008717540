<template>
    <svg width="42" height="11" viewBox="0 0 42 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.97163 5.05718C7.62925 4.54135 7.95806 3.7965 7.95806 2.82261C7.95806 1.88587 7.64924 1.18228 7.03162 0.711843C6.41843 0.237281 5.52087 0 4.33894 0H1.22637V9.04969H3.29253V5.83092H4.17898C5.38757 5.83092 6.31846 5.57301 6.97163 5.05718ZM3.97236 4.25868H3.29253V1.57224H4.2323C4.79661 1.57224 5.20984 1.67954 5.472 1.89412C5.7386 2.10871 5.8719 2.4409 5.8719 2.8907C5.8719 3.33638 5.71416 3.67682 5.39868 3.91204C5.0832 4.14313 4.60776 4.25868 3.97236 4.25868Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.9766 8.10882L14.3698 9.04969H15.7895V4.43819C15.7895 3.61286 15.5229 2.99799 14.9897 2.59359C14.4565 2.18918 13.69 1.98697 12.6902 1.98697C11.646 1.98697 10.6952 2.19537 9.8376 2.61215L10.5108 3.88728C11.315 3.55303 12.0148 3.3859 12.6103 3.3859C13.3834 3.3859 13.77 3.73666 13.77 4.43819V4.74149L12.477 4.77863C11.3617 4.81577 10.5263 5.00973 9.9709 5.36049C9.41992 5.70713 9.14443 6.24771 9.14443 6.98225C9.14443 7.68378 9.34883 8.22437 9.75762 8.60402C10.1708 8.98367 10.7352 9.17349 11.4505 9.17349C12.0326 9.17349 12.5036 9.09715 12.8635 8.94446C13.2279 8.78765 13.5811 8.5091 13.9233 8.10882H13.9766ZM12.9835 5.86806L13.77 5.8433V6.41278C13.77 6.82957 13.6278 7.16382 13.3434 7.41555C13.0635 7.66727 12.688 7.79314 12.217 7.79314C11.5594 7.79314 11.2306 7.52697 11.2306 6.99463C11.2306 6.62324 11.375 6.34675 11.6638 6.16518C11.9526 5.98361 12.3925 5.88457 12.9835 5.86806Z"
            fill="currentColor"
        />
        <path
            d="M22.7344 2.05506C22.5523 2.01792 22.3234 1.99935 22.0479 1.99935C21.5858 1.99935 21.1571 2.11902 20.7616 2.35837C20.3706 2.59359 20.0595 2.90515 19.8285 3.29305H19.7285L19.4286 2.12934H17.889V9.04969H19.9218V5.52762C19.9218 4.97052 20.1018 4.53723 20.4617 4.22773C20.826 3.91823 21.3326 3.76348 21.9813 3.76348C22.2168 3.76348 22.4167 3.78412 22.5812 3.82538L22.7344 2.05506Z"
            fill="currentColor"
        />
        <path
            d="M28.5064 7.45269C28.0087 7.59712 27.5822 7.66934 27.2267 7.66934C26.9601 7.66934 26.7446 7.60537 26.5802 7.47745C26.4202 7.34952 26.3402 7.15144 26.3402 6.88321V3.54684H28.4264V2.12934H26.3402V0.656134H25.0406L24.454 2.11696L23.3343 2.74833V3.54684H24.3074V6.88321C24.3074 7.64664 24.4918 8.22024 24.8606 8.60402C25.2338 8.98367 25.827 9.17349 26.6402 9.17349C27.3778 9.17349 27.9998 9.06826 28.5064 8.8578V7.45269Z"
            fill="currentColor"
        />
        <path d="M33.7251 0V9.04969H35.7913V0H33.7251Z" fill="currentColor" />
        <path d="M38.244 0V9.04969H40.3102V0H38.244Z" fill="currentColor" />
        <path d="M0 11H42V10.0248H0V11Z" fill="currentColor" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconSectionHeader',
})
</script>
