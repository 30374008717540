<template>
    <svg width="43" height="17" viewBox="0 0 43 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 4V0H1L1 4L41.0233 4V5L1 5H0V4ZM0 17V12L0.000183105 12L1 12H41.0234H42.0234V17H41.0234V13L1 13L1 17H0ZM41.0234 0V5H42.0234V0H41.0234ZM0 9H1.46512L1.46512 7.5H0V9ZM4.39535 9L7.32558 9L7.32558 7.5H4.39535L4.39535 9ZM10.2558 9H13.186V7.5H10.2558V9ZM16.1163 9L19.0465 9V7.5H16.1163V9ZM21.9767 9H24.907V7.5L21.9767 7.5V9ZM27.8372 9H30.7674V7.5L27.8372 7.5V9ZM33.6977 9H36.6279V7.5L33.6977 7.5V9ZM39.5581 9H41.0233V7.5L39.5581 7.5V9Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconPageBreak',
})
</script>
