<template>
    <svg width="42" height="19" viewBox="0 0 42 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42 1.46154H0V0H42V1.46154Z" fill="currentColor" />
        <path d="M42 19H0V17.5385H42V19Z" fill="currentColor" />
        <path d="M0 10.2308H2.625V8.76923H0V10.2308Z" fill="currentColor" />
        <path d="M7.875 10.2308H13.125V8.76923H7.875V10.2308Z" fill="currentColor" />
        <path d="M18.375 10.2308H23.625V8.76923H18.375V10.2308Z" fill="currentColor" />
        <path d="M28.875 10.2308H34.125V8.76923H28.875V10.2308Z" fill="currentColor" />
        <path d="M39.375 10.2308H42V8.76923H39.375V10.2308Z" fill="currentColor" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconHandwriting',
})
</script>
