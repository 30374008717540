<template>
    <svg width="42" height="7" viewBox="0 0 42 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.11122 6.87307H3.6794V0H2.39034L0 1.76293L0.756181 2.63734L1.60879 2.00269C1.71029 1.92747 1.8913 1.7692 2.15182 1.52787L2.12644 2.24244L2.11122 2.8959V6.87307Z"
            fill="currentColor"
        />
        <path
            d="M6.14587 5.60376C5.99362 5.73853 5.91749 5.93754 5.91749 6.20081C5.91749 6.45467 5.99531 6.65212 6.15095 6.79315C6.30658 6.93105 6.5265 7 6.8107 7C7.08814 7 7.30467 6.92948 7.46031 6.78845C7.61594 6.64428 7.69376 6.4484 7.69376 6.20081C7.69376 5.94381 7.61594 5.74636 7.46031 5.60846C7.30805 5.47056 7.09152 5.40161 6.8107 5.40161C6.51973 5.40161 6.29812 5.46899 6.14587 5.60376Z"
            fill="currentColor"
        />
        <path d="M11.8583 6.87307H42V5.91028H11.8583V6.87307Z" fill="currentColor" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconOpenResponse',
})
</script>
