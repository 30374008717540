<template>
    <svg width="40" height="4" viewBox="0 0 40 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 2C4 3.10455 3.10457 4 2 4C0.895432 4 0 3.10455 0 2C0 0.895447 0.895432 0 2 0C3.10457 0 4 0.895447 4 2Z"
            fill="currentColor"
        />
        <path
            d="M40 2C40 3.10455 39.1046 4 38 4C36.8954 4 36 3.10455 36 2C36 0.895447 36.8954 0 38 0C39.1046 0 40 0.895447 40 2Z"
            fill="currentColor"
        />
        <path
            d="M8 4C9.10457 4 10 3.10455 10 2C10 0.895447 9.10457 0 8 0C6.89543 0 6 0.895447 6 2C6 3.10455 6.89543 4 8 4Z"
            fill="currentColor"
        />
        <path
            d="M16 2C16 3.10455 15.1046 4 14 4C12.8954 4 12 3.10455 12 2C12 0.895447 12.8954 0 14 0C15.1046 0 16 0.895447 16 2Z"
            fill="currentColor"
        />
        <path
            d="M32 4C33.1046 4 34 3.10455 34 2C34 0.895447 33.1046 0 32 0C30.8954 0 30 0.895447 30 2C30 3.10455 30.8954 4 32 4Z"
            fill="currentColor"
        />
        <path
            d="M22 2C22 3.10455 21.1046 4 20 4C18.8954 4 18 3.10455 18 2C18 0.895447 18.8954 0 20 0C21.1046 0 22 0.895447 22 2Z"
            fill="currentColor"
        />
        <path
            d="M26 4C27.1046 4 28 3.10455 28 2C28 0.895447 27.1046 0 26 0C24.8954 0 24 0.895447 24 2C24 3.10455 24.8954 4 26 4Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconDivider',
})
</script>
