<template>
    <Component :is="fullIconName" v-if="fullIconName" class="duration-0" :class="itemClass" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { colorTypeOptions, iconOptions, iconSizeOptions } from '../../mocks/StorybookOptions.mock'
import IconArrowLeftCircle from './IconArrowLeftCircle.vue'
import IconCaretRightFill from './IconCaretRightFill.vue'
import IconCircle from './IconCircle.vue'
import IconCircleFill from './IconCircleFill.vue'
import IconCircleRecord from './IconCircleRecord.vue'
import IconCheck from './IconCheck.vue'
import IconCross from './IconCross.vue'
import IconCrossCircle from './IconCrossCircle.vue'
import IconGem from './IconGem.vue'
import IconHeart from './IconHeart.vue'
import IconMoon from './IconMoon.vue'
import IconSmile from './IconSmile.vue'
import IconStar from './IconStar.vue'
import IconSquare from './IconSquare.vue'
import IconSun from './IconSun.vue'
import IconGear from './IconGear.vue'
import IconInfoCircle from './IconInfoCircle.vue'
import IconUpDownArrow from './IconUpDownArrow.vue'
import IconBlankSpace from './IconBlankSpace.vue'
import IconBasicText from './IconBasicText.vue'
import IconSectionHeader from './IconSectionHeader.vue'
import IconChecklist from './IconChecklist.vue'
import IconDivider from './IconDivider.vue'
import IconFillInTheBlank from './IconFillInTheBlank.vue'
import IconHandwriting from './IconHandwriting.vue'
import IconInstruction from './IconInstruction.vue'
import IconMatching from './IconMatching.vue'
import IconMultipleChoice from './IconMultipleChoice.vue'
import IconOpenResponse from './IconOpenResponse.vue'
import IconPageBreak from './IconPageBreak.vue'
import IconWordBank from './IconWordBank.vue'
import IconWordScramble from './IconWordScramble.vue'
import IconPalette from './IconPalette.vue'
import IconA from './IconA.vue'
import IconFile from './IconFile.vue'
import IconMagnifyPlus from './IconMagnifyPlus.vue'
import IconEightDots from './IconEightDots.vue'
import IconEyeSlash from './IconEyeSlash.vue'
import IconEye from './IconEye.vue'
import IconDuplicate from './IconDuplicate.vue'
import IconDelete from './IconDelete.vue'
import IconPlus from './IconPlus.vue'
import IconX from './IconX.vue'

export default defineComponent({
    name: 'IconLoader',
    components: {
        IconA,
        IconArrowLeftCircle,
        IconBasicText,
        IconBlankSpace,
        IconCaretRightFill,
        IconCircle,
        IconCircleFill,
        IconCircleRecord,
        IconCheck,
        IconChecklist,
        IconCross,
        IconCrossCircle,
        IconDivider,
        IconFillInTheBlank,
        IconFile,
        IconGem,
        IconHeart,
        IconInfoCircle,
        IconMoon,
        IconSmile,
        IconSquare,
        IconStar,
        IconSun,
        IconGear,
        IconHandwriting,
        IconInstruction,
        IconMatching,
        IconMultipleChoice,
        IconOpenResponse,
        IconPageBreak,
        IconSectionHeader,
        IconPalette,
        IconMagnifyPlus,
        IconUpDownArrow,
        IconWordBank,
        IconWordScramble,
        IconEightDots,
        IconEyeSlash,
        IconEye,
        IconDuplicate,
        IconDelete,
        IconPlus,
        IconX,
    },
    props: {
        icon: {
            type: String,
            required: true,
            validator: (value: string) => iconOptions.includes(value),
        },
        type: {
            type: String,
            default: null,
            validator: (value: string) => colorTypeOptions.includes(value),
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => iconSizeOptions.includes(value),
        },
    },
    computed: {
        fullIconName(): string | null {
            if (!this.$options.components) return null

            const iconName = `Icon${this.icon}`
            return iconName in this.$options.components ? iconName : null
        },
        itemClass(): object {
            return {
                [`text-${this.type}`]: !!this.type,
                icon: true,
                [`icon-${this.size}`]: !!this.size,
            }
        },
    },
})
</script>

<style scoped lang="scss">
svg {
    display: inline;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;

    &-xs,
    &-tiny {
        width: 1rem;
        height: 1rem;
    }

    &-sm {
        width: 1.25rem;
        height: 1.25rem;
    }

    &-lg {
        width: 1.875rem;
        height: 1.875rem;
    }

    &-xl {
        width: 2.5rem;
        height: 2.5rem;
    }
}
</style>
