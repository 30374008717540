<template>
    <svg width="42" height="17" viewBox="0 0 42 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.86667 0.75H40.1333C40.3435 0.75 40.6047 0.868069 40.8428 1.20173C41.0799 1.53392 41.25 2.03133 41.25 2.61538V14.3846C41.25 14.9687 41.0799 15.4661 40.8428 15.7983C40.6047 16.1319 40.3435 16.25 40.1333 16.25H1.86667C1.65654 16.25 1.39534 16.1319 1.1572 15.7983C0.920105 15.4661 0.75 14.9687 0.75 14.3846V2.61538C0.75 2.03133 0.920105 1.53392 1.1572 1.20173C1.39534 0.868069 1.65654 0.75 1.86667 0.75Z"
            stroke="currentColor"
            stroke-width="1.5"
        />
        <path
            d="M4.76579 3.71003L37.0963 3.84801C37.4969 3.84972 37.9218 4.22308 37.9193 4.81207L37.8864 12.5094C37.8839 13.0984 37.4558 13.4681 37.0552 13.4664L4.72474 13.3284C4.32412 13.3267 3.89922 12.9533 3.90174 12.3643L3.93459 4.66702C3.9371 4.07804 4.36517 3.70832 4.76579 3.71003Z"
            stroke="currentColor"
            stroke-width="1.5"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconWordBank',
})
</script>
