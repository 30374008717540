<template>
    <svg width="42" height="18" viewBox="0 0 42 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 7.75H42M11 16.75H42M1 1H8V7.75H4H1V1ZM1 10H8V16.75H1V10Z" stroke="currentColor" stroke-width="1.5" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconChecklist',
})
</script>
