<template>
    <svg
        class="bi b-icon"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        stroke="currentColor"
        fill="currentColor"
        width="20"
        height="20"
    >
        <defs>
            <path
                id="text-path-0"
                d="M 3.7090001106262207 15.151793241500854 Q 8.00025224685669 0.847618818283081 12.291504383087158 15.151793241500854"
            />
        </defs>
        <rect x="1" y="1" width="14" height="14" style="fill: none; stroke-miterlimit: 7; stroke-linejoin: round" />
        <text
            style="font-family: Arial, sans-serif; font-size: 12.8px; stroke-miterlimit: 1; white-space: pre"
            transform="matrix(0.438371, 0.898794, -0.898794, 0.438371, 15.445608, 2.922325)"
        >
            <textPath xlink:href="#text-path-0">A</textPath>
        </text>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconA',
})
</script>
