<template>
    <svg width="42" height="14" viewBox="0 0 42 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 3C6 3.1895 5.98243 3.3749 5.94883 3.55465L21.2541 6.53069L36.0115 2.73595C36.1452 1.20276 37.4321 0 39 0C40.6569 0 42 1.34315 42 3C42 4.65685 40.6569 6 39 6C37.6012 6 36.4259 5.04259 36.0938 3.7473L23.5406 6.97528L36.4139 9.47843C36.9357 8.59363 37.8985 8 39 8C40.6569 8 42 9.34315 42 11C42 12.6569 40.6569 14 39 14C37.3431 14 36 12.6569 36 11C36 10.8041 36.0188 10.6127 36.0546 10.4273L21.2847 7.55536L5.95915 11.4962C5.72267 12.9169 4.48782 14 3 14C1.34315 14 0 12.6569 0 11C0 9.34315 1.34315 8 3 8C4.47439 8 5.70036 9.0636 5.95251 10.4654L18.9983 7.11077L5.5959 4.50476C5.07651 5.39884 4.10846 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconMatching',
})
</script>
