<template>
    <svg width="42" height="18" viewBox="0 0 42 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.2834 3.96184H18.1766V13.8462H21.9116C23.0405 13.8462 23.9327 13.5938 24.5882 13.0889C25.2483 12.5841 25.5783 11.881 25.5783 10.9796C25.5783 10.3441 25.4372 9.83474 25.1549 9.45162C24.8773 9.06851 24.422 8.80709 23.7893 8.66737V8.59976C24.2536 8.52314 24.6246 8.29552 24.9023 7.91692C25.1845 7.53831 25.3256 7.05379 25.3256 6.46334C25.3256 5.59345 25.0047 4.96019 24.3629 4.56355C23.7256 4.16241 22.6991 3.96184 21.2834 3.96184ZM21.5224 7.87635H20.2933V5.67909H21.4063C22.0117 5.67909 22.4533 5.76247 22.7309 5.92924C23.0132 6.0915 23.1543 6.36193 23.1543 6.74054C23.1543 7.14618 23.0245 7.4369 22.7651 7.61268C22.5102 7.78846 22.0959 7.87635 21.5224 7.87635ZM20.2933 12.1154V9.53951H21.6043C22.7878 9.53951 23.3796 9.94967 23.3796 10.77C23.3796 11.2252 23.2408 11.5633 22.9631 11.7841C22.6854 12.005 22.2552 12.1154 21.6726 12.1154H20.2933Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.7649 9C30.7649 13.9706 26.6954 18 21.6753 18C16.6552 18 12.5857 13.9706 12.5857 9C12.5857 4.02944 16.6552 0 21.6753 0C26.6954 0 30.7649 4.02944 30.7649 9ZM29.3665 9C29.3665 13.2059 25.9231 16.6154 21.6753 16.6154C17.4276 16.6154 13.9841 13.2059 13.9841 9C13.9841 4.79414 17.4276 1.38462 21.6753 1.38462C25.9231 1.38462 29.3665 4.79414 29.3665 9Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.6438 11.4934L7.36758 13.8462H9.64819L6.1112 3.92127H3.52333L0 13.8462H2.2806L3.00439 11.4934H6.6438ZM5.00504 6.11854C5.09153 6.39799 5.46935 7.60367 6.13851 9.73558H3.53699C4.24711 7.45943 4.67729 6.0329 4.82751 5.45598C4.86392 5.61824 4.9231 5.83909 5.00504 6.11854Z"
            fill="currentColor"
        />
        <path
            d="M37.2271 6.45658C37.6641 5.86163 38.2809 5.56415 39.0775 5.56415C39.4417 5.56415 39.8127 5.62951 40.1905 5.76022C40.5684 5.89093 40.9439 6.04417 41.3172 6.21995L42 4.51623C41.0213 4.05198 40.0471 3.81986 39.0775 3.81986C38.1262 3.81986 37.2954 4.02719 36.5853 4.44186C35.8797 4.85201 35.338 5.44471 34.9602 6.21995C34.5823 6.99069 34.3934 7.88987 34.3934 8.91752C34.3934 10.5491 34.7781 11.8021 35.5474 12.6765C36.3167 13.5464 37.4274 13.9814 38.8795 13.9814C39.8901 13.9814 40.8051 13.8124 41.6245 13.4743V11.7165C40.6275 12.0636 39.7786 12.2371 39.0775 12.2371C37.4069 12.2371 36.5716 11.1351 36.5716 8.93104C36.5716 7.87184 36.7901 7.04703 37.2271 6.45658Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconMultipleChoice',
})
</script>
