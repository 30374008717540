<template>
    <svg width="42" height="13" viewBox="0 0 42 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M37.4182 0V1.77273C39.9496 1.77273 42 3.88818 42 6.5C42 7.42773 41.7365 8.29045 41.2898 9.01727L40.4536 8.15455C40.7114 7.66409 40.8545 7.09682 40.8545 6.5C40.8545 4.54409 39.3139 2.95455 37.4182 2.95455V4.72727L35.1273 2.36364L37.4182 0Z"
            fill="currentColor"
        />
        <path
            d="M33.9818 6.5C33.9818 8.45591 35.5225 10.0455 37.4182 10.0455V8.27273L39.7091 10.6364L37.4182 13V11.2273C34.8867 11.2273 32.8364 9.11182 32.8364 6.5C32.8364 5.57227 33.0998 4.70955 33.5465 3.98273L34.3827 4.84545C34.125 5.33591 33.9818 5.90318 33.9818 6.5Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.25604 9.33759L8.04652 12.0152H10.5373L6.67436 0.72017H3.84801L0 12.0152H2.49077L3.28125 9.33759H7.25604ZM5.46626 3.22076C5.56072 3.53879 5.97337 4.91091 6.70419 7.33712H3.86293C4.63849 4.74676 5.10831 3.1233 5.27237 2.46674C5.31214 2.6514 5.37678 2.90274 5.46626 3.22076Z"
            fill="currentColor"
        />
        <path
            d="M14.527 3.60547C15.0043 2.92839 15.6779 2.58984 16.5479 2.58984C16.9457 2.58984 17.3509 2.66422 17.7635 2.81297C18.1761 2.96173 18.5863 3.13613 18.994 3.33617L19.7397 1.39725C18.6708 0.868924 17.6069 0.604759 16.5479 0.604759C15.5089 0.604759 14.6016 0.840712 13.826 1.31262C13.0554 1.7794 12.4638 2.45391 12.0511 3.33617C11.6385 4.2133 11.4322 5.23662 11.4322 6.40613C11.4322 8.26298 11.8523 9.68896 12.6925 10.6841C13.5327 11.674 14.7457 12.169 16.3317 12.169C17.4354 12.169 18.4347 11.9767 19.3295 11.592V9.5915C18.2408 9.98647 17.3136 10.1839 16.5479 10.1839C14.7234 10.1839 13.8111 8.92981 13.8111 6.42152C13.8111 5.21611 14.0497 4.27742 14.527 3.60547Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.0494 0.766335H21.6562V12.0152H25.7354C26.9684 12.0152 27.9428 11.7279 28.6587 11.1534C29.3796 10.5789 29.7401 9.77872 29.7401 8.75284C29.7401 8.02959 29.5859 7.44997 29.2777 7.01397C28.9744 6.57797 28.4773 6.28046 27.7862 6.12145V6.04451C28.2933 5.95731 28.6985 5.69827 29.0018 5.2674C29.31 4.83653 29.4641 4.28512 29.4641 3.61316C29.4641 2.62318 29.1136 1.9025 28.4126 1.45111C27.7166 0.994594 26.5955 0.766335 25.0494 0.766335ZM25.3104 5.22124H23.968V2.72064H25.1836C25.8448 2.72064 26.3271 2.81554 26.6303 3.00533C26.9386 3.18999 27.0927 3.49775 27.0927 3.92862C27.0927 4.39027 26.951 4.72112 26.6676 4.92116C26.3892 5.12121 25.9368 5.22124 25.3104 5.22124ZM23.968 10.0455V7.11399H25.3999C26.6925 7.11399 27.3388 7.58077 27.3388 8.51432C27.3388 9.03239 27.1871 9.4171 26.8839 9.66844C26.5806 9.91978 26.1108 10.0455 25.4744 10.0455H23.968Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconWordScramble',
})
</script>
