<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-circle-fill"
        viewBox="0 0 16 16"
    >
        <circle cx="8" cy="8" r="8" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconCircleFill',
})
</script>
